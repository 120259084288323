<template>


    <div class="loader_box">
        <div class="sk-double-bounce">
    <div class="sk-child sk-double-bounce-1"  :style="`background-color:${selectedColor};`"></div>
    <div class="sk-child sk-double-bounce-2"  :style="`background-color:${selectedColor};`"></div>
  </div>
        </div>
    </template>
    <script>
    export default{
        props:['selectedColor'],
    }
    </script>
    <style scoped>
    .loader_box{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        z-index:999
    }
    
    .sk-double-bounce {
  width: 64px;
  height: 64px;
  position: relative;
  margin: auto;
}

.sk-double-bounce .sk-child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* background-color: var(--spinkit-spinner-color); */
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-double-bounce 2.0s infinite ease-in-out;
}

.sk-double-bounce .sk-double-bounce-2 {
  animation-delay: -1.0s;
}

@keyframes sk-double-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.0);
  }
}

    </style>